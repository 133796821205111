import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1>Creations of Digital Origin, LLC</h1>
      <h3>jake.otoole@codo.services</h3>
      <h3>(612) 404-0160</h3>
    </div>
  );
}

export default App;
